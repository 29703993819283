import React from "react";

export default function RugzHomeBoatScene() {
  return (    
          <div>
            <div className="bg-blue text-white d-flex-row">
                 <div> <h3 className="text-center pt-1">How Did I Get Here?</h3></div>
                    <div className="row text-center mr-3">
                         <div className ="col"></div>
                         <div className="col-10 text-center text-white">
                              <p>
                                You just hit 10x on a Not Okay MFer Kevin Goblin derivative. Artchick
                                just pulled another rug while Beanie is still shilling wolf game.
                                You just aped into a new project not realizing you were Faroks
                                exit liquidity. Ryder and Pauly are blowing each other in the
                                bathroom claiming it's "art." We've all been rugged before,
                                its now time to ride into the tempestuous broken sea and
                                reclaim the ETH once and for all.

  
                                
                              </p>             
                           </div>
                         <div className ="col"></div>
                      </div>
               </div>
          </div>     
  );
}
