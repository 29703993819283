import React from "react";
import spashPage from "../images/splashpage.png";

export default function RugzHomeSpashPage() {
  return (    
          <div className="d-flex-row bg-navy-purple">
               <div className="">
                    <img src={spashPage} className="imgshadow img-fluid" alt="splash page" />
               </div>
          </div>   
  );
}
