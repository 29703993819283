import React from "react";
import boatscene from "../images/boatscene7.png";

export default function RugzHomeBoatScene() {
  return (    
          <div className="d-flex-row bg-navy-purple">
               <div className="">
                    <img src={boatscene} className="imgshadow img-fluid" alt="boat scene" />
               </div>
          </div>   
  );
}
