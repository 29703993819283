import React from "react";

export default function RugzHomeGoMintTrailer() {
  return (    
          <div className="d-flex-row text-white text-center">
            <div className="pt-4">You still here? what you waiting for?</div>
            <div className="pb-2">Go mint your notorious rugs</div>
          </div>   
  );
}
