import React, { Component } from 'react'
import CurrencyPayableUsd from './CurrencyPayableUsd'
import axios from 'axios'

import { getEthPriceApi } from '../constants/cryptoApiUrl.js'

let coinArray = []
// Coin price template
let coinInfo = {
  id: 1,
  name: 'Ethereum',
  symbol: 'ETH',
  price_usd: 1
}

function toArrayFromAxiosApiObj(apiResponseData) {
    // Clear the array otherwise it will be appended to on every call. Every execution will add an additional <div> though the price display will be jumbled
    coinArray = []
    // Load the array in the same order as response which is returned in the same order as the request
    coinInfo = {
        price_usd: apiResponseData['ethereum'].usd,
        id: 1,
        name: 'Ethereum',
        symbol: 'ETH'
    }
    coinArray.push(coinInfo)
}
  
class CryptoEth extends Component {
  fetchCurrencyData = () => {
    console.log('test CryptoEth class before fetchCurrencyData with an api of ', getEthPriceApi)
    axios 
      //.get(apiListOfCoinsUrl) -- single get returns  or if to run multipe use the .all either way an object is rturned for each get
      .all([
          axios.get(getEthPriceApi),        
          ])
      .then(response => {
        toArrayFromAxiosApiObj(response[0].data)
        this.setState({ data: coinArray })
      })
      .catch(err => console.log(`Axios error getting prices for coins: ${err}`))
  }
  componentDidMount() {
    this.fetchCurrencyData()
    // set timer to get price every minute
    this.interval = setInterval(() => this.fetchCurrencyData(), 60 * 1000)
  }
  constructor(props) {
    super(props)
    this.state = {
      data: [
        {
          id: '1',
          name: 'Ethereum',
          symbol: 'ETH',
          price_usd: '1',
        }
      ],
    }
  }
  render() {  
    let cryptoEth = this.state.data.map(currency => (
      <CurrencyPayableUsd data={currency} key={currency.id} />
    ))

    return (
        <> {cryptoEth} </>
    )
  }
}

export default CryptoEth