import React, { Component } from 'react'
import Currency from './Currency'
import axios from 'axios'

import { getPriceApi } from '../constants/cryptoApiUrl.js'

import './Crypto.css'

let coinArray = []
// Coin price template
let coinInfo = {
  id: 1,
  name: 'Ethereum',
  symbol: 'ETH',
  price_usd: 1
}

function toArrayFromAxiosApiObj(apiResponseData) {
    // Clear the array otherwise it will be appended to on every call. Every execution will add an additional <div> though the price display will be jumbled
    coinArray = []
  
    // Load the array in the same order as response which is returned in the same order as the request
    coinInfo = {
        price_usd: apiResponseData['ethereum'].usd,
        id: 1,
        name: 'Ethereum',
        symbol: 'ETH'
    }
    coinArray.push(coinInfo)
        
    coinInfo = {
        price_usd: apiResponseData['bitcoin'].usd,
        id: 2,
        name: 'Bit Coin',
        symbol: 'BTC'
    }
    coinArray.push(coinInfo)

    coinInfo = {
        price_usd: apiResponseData['litecoin'].usd,
        id: 3,
        name: 'Lite Coin',
        symbol: 'LITE'
    }
    coinArray.push(coinInfo)
}
  
class Crypto extends Component {
  fetchCurrencyData = () => {
    console.log('test0 Crypto class before fetchCurrencyData with an api of ', getPriceApi)
    axios 
      //.get(apiListOfCoinsUrl) -- single get returns  or if to run multipe use the .all either way an object is rturned for each get
      .all([
        axios.get(getPriceApi)
      // axios.get(apiEthUrl), use one get for all coins. becuase .all is used each an array of .data is returned
      // axios.get(apiBtcUrl),
      // axios.get(apiLiteUrl)
          ])
      .then(response => {
        toArrayFromAxiosApiObj(response[0].data)
        this.setState({ data: coinArray })
      })
      .catch(err => console.log(`Axios error getting prices for coins: ${err}`))
  }
  componentDidMount() {
    this.fetchCurrencyData()
    // set timer to get price every minute
    this.interval = setInterval(() => this.fetchCurrencyData(), 60 * 1000)
  }
  constructor(props) {
    super(props)
    this.state = {
      data: [
        {
          id: '1',
          name: 'Ethereum',
          symbol: 'ETH',
          price_usd: '1',
        },
        {
          id: '2',
          name: 'Bit Coin',
          symbol: 'BTC',
          price_usd: '1',
        },
        {
          id: '3',
          name: 'Lite Coin',
          symbol: 'Lite',
          price_usd: '1',
        }
      ],
    }
  }
  render() {  
    let crypto = this.state.data.map(currency => (
      <Currency data={currency} key={currency.id} />
    ))

    return (
      <div className="crypto-container text-center">
        <p className='text-white'>Current USD Price</p>
        <ul className="crypto"> {crypto} </ul>
      </div>
    )
  }
}

export default Crypto