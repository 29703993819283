import React from "react";
export default function About() {
    return (
      <>
        <div className="pl-5 pt-5 text-white">
                <h4 className='font-weight-bold'>Is this a rug pull?</h4>
                <p>Head over to etherscan and check out our contract. You&apos;ll find it&apos;s safe!</p>
        </div>
        <div className="pl-5 text-white">
          <h3 className="text-warning">FYI - Coin Prices</h3>
          <p>Coin prices start in an initial default state of 1, they are updated on the initial display and every minute after</p>
          <p>If you see a coin price of 1, it is an indicatation of a failed get price API</p>
          <p>The inability to get the latest ETH price will prevent the calculation of the the payable NFTs when converting the eth to usd</p>
          <p>Failed APIs have been due to CORS - this out of our control. Eventually CORS lets the API thru but the timeframe varies</p>
        </div>
    </>
  );
}