import React from "react";
import {
  Navbar,
  Nav,
  Button,
} from "react-bootstrap";
import "./MenuNavBar.css";
import MintButton from "./MintButton.js"

export default function MenuNavBar() {
     
  return (
    <>
    <Navbar className="max-width-100-pct" fixed="top" variant="light" bg="black" xexpand="sm">
      <Navbar.Brand href="/"> 
        {/* image is located in public/images */}
        <img src="images/favicon.png" id="logo" alt="logo" className="img-fluid pl-5" />
      </Navbar.Brand>
      <Nav.Link href="/About">About</Nav.Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
        </Nav>
      </Navbar.Collapse>
      {/* images are located in public/images */}
      <a href="https://twitter.com/notoriousrugs"><img src="images/twitter.png" alt="Twitter" /></a>
      <a href="https://opensea.io/collection/notorious-rugs"><img src="images/opensea.png" alt="Open Sea" /></a>
        {/* <Button variant="success" id="mint" size="md" className="ml-2 mr-5 p-1" href="http://www.mint.notoriousrugs.io/">Mint</Button> */}
        <MintButton btnText={"Mint"} btnId={"mint"} btnSize={"md"} btnClass={"ml-2 mr-5 p-1" } />
    </Navbar>
    </>
  );
}
