import React from "react";
import RugzHome from "../Components/RugzHome";
// import RugzFooter from "../Components/RugzFooter";
// import Slideshow from "../Components/SlideShow";

export default function Home() {
  return (
    <div className="Home-header-background pl-2">
      <RugzHome />
      {/* <RugzFooter /> */}
    </div>
  );
}
