import React from "react";
import rugz from "../images/rugs8.png";

export default function RugzHomeShowRoom() {
  return (    
    <div className="d-flex-row text-center bg-blue-navy">
      <div className="col"></div>
               <div className="col text-center pt-5">
                    <img src={rugz} className="imgshadow img-fluid mx-auto ximgshowroomsize" alt="showroom" />
      </div>
      <div className="col"></div>
    </div>  
    
  );
}
