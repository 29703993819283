import React from 'react'
import './Currency.css'

const Currency = props => {
  const {
    name,
    symbol,
    price_usd,
  } = props.data
  return (
    <li className="currency">
      <p className="currency-name">
        {name} ({symbol})
      </p>
      {/* <h4>${(+price_usd).toFixed(2)}</h4> */}
      <h4>{(price_usd).toLocaleString("en-us", {style:"currency", currency: "USD" })}</h4>
      </li>
  )
}

export default Currency